import Modal from '../../shared/ui/Modal';
import styles from './index.module.scss';
import { SignInForm } from '../../features/authentication/signIn';
import { SignUpForm } from '../../features/authentication/signUp';
import { ResetPassForm } from '../../features/authentication/resetPass';
import {
    changeModalVisibleStatus,
    redirectToSignIn,
    redirectToSignUp,
    redirectToResetPass,
    getModalStatus,
    getCurrentRote,
} from '../../entities/authModal';
import { useLocation } from 'react-router-dom';
import { ResendAuthMesgForm } from '../../features/authentication/resendAuthMesg';
import { useAppSelector, useAppDispatch } from '../../shared/store';
import { useEffect } from 'react';

const regUrl =
    '/%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81%D1%8B/%D1%80%D0%B5%D0%B3%D0%B8%D1%81%D1%82%D1%80%D0%B0%D1%86%D0%B8%D1%8F';

const AuthFormRouter = () => {
    const modalStatus = useAppSelector(getModalStatus);
    const currenRoute = useAppSelector(getCurrentRote);
    const { pathname } = useLocation();
    const dispatch = useAppDispatch();
    const toggleModalstatus = () => {
        dispatch(changeModalVisibleStatus());
    };

    const navigateToSignUp = () => {
        dispatch(redirectToSignUp());
    };

    const navigateToSignIn = () => {
        dispatch(redirectToSignIn());
    };

    const navigateToResetPass = () => {
        dispatch(redirectToResetPass());
    };
    const signInRouting = (
        <ul className={styles['auth-router__nav-list']}>
            <li className={styles['auth-router__nav-list-item']} onClick={navigateToSignUp}>
                Регистрация
            </li>
            <li className={styles['auth-router__nav-list-item']} onClick={navigateToResetPass}>
                Восстановить пароль
            </li>
        </ul>
    );

    const resetPassRouting = (
        <ul className={styles['auth-router__nav-list']}>
            <li className={styles['auth-router__nav-list-item']} onClick={navigateToSignIn}>
                Вход
            </li>
            <li className={styles['auth-router__nav-list-item']} onClick={navigateToSignUp}>
                Регистрация
            </li>
        </ul>
    );

    const signUpRouting = (
        <ul className={styles['auth-router__nav-list']}>
            <li className={styles['auth-router__nav-list-item']} onClick={navigateToSignIn}>
                Вход
            </li>
            <li className={styles['auth-router__nav-list-item']} onClick={navigateToResetPass}>
                Восстановить пароль
            </li>
        </ul>
    );

    const currentForm = {
        signIn: (
            <SignInForm
                navMenu={signInRouting}
                setModalStatus={toggleModalstatus}
                actionBtn={<ResendAuthMesgForm />}
            />
        ),
        resetPassword: <ResetPassForm navMenu={resetPassRouting} />,
        signUp: <SignUpForm navMenu={signUpRouting} setModalStatus={toggleModalstatus} />,
    }[currenRoute];

    useEffect(() => {
        if (pathname.includes(regUrl)) {
            toggleModalstatus();
            navigateToSignUp();
        }
    }, [pathname]);

    return (
        <Modal
            isOpen={modalStatus}
            setStatus={toggleModalstatus}
            className={styles['auth-router__modal']}
        >
            {currentForm}
        </Modal>
    );
};

export default AuthFormRouter;
