import { createBrowserRouter } from 'react-router-dom';
import { APP__ROUTS } from '../../shared/consts';
import { MainLayout } from '../../pages/MainLayout';
import { loadFeatById } from '../../entities/feat/model/loader';
import { loadGalleryMediaItemById } from '../../entities/gallery/model/loader';
import { Navigate } from 'react-router-dom';
import { Suspense } from 'react';
import PagePreloader from '../../shared/ui/PagePreloader';
import ProfileLayout from '../../pages/ProfileLayout/index';
import AdminLayout from '../../pages/AdminLayout';
import { lazyRetry } from '../../shared/utils/lazyRetry';

const MainPageLazy = lazyRetry(() => import('../../pages/MainPage/index'));
const GalleryPageLazy = lazyRetry(() => import('../../pages/GalleryPage/index'));
const FeatsPageLazy = lazyRetry(() => import('../../pages/FeatsPage/index'));
const GalleryPopUpLazy = lazyRetry(
    () => import('../../pages/GalleryPage/ui/GalleryPreloaderModal')
);
const FeatPopUpLazy = lazyRetry(() => import('../../pages/FeatsPage/ui/FeatPreloaderModal'));
const ContestPageLazy = lazyRetry(() => import('../../pages/ContestsPage/index'));

const PuzzlePageLazy = lazyRetry(() => import('../../pages/PuzzlePage/index'));
const PuzzleStartPageLazy = lazyRetry(() => import('../../pages/PuzzlePage/ui/PuzzleStartPage'));
const PuzzleGamePageLazy = lazyRetry(() => import('../../pages/PuzzlePage/ui/PuzzleGame'));
const PuzzleTestPageLazy = lazyRetry(() => import('../../pages/PuzzlePage/ui/PuzzleTest/index'));
const PuzzleFinalLvl = lazyRetry(() => import('../../pages/PuzzlePage/ui/FinalLvl/index'));
const PuzzleRefQuestion = lazyRetry(() => import('../../pages/PuzzlePage/ui/RefQuestion/index'));

const UserPageLazy = lazyRetry(() => import('../../pages/UserPage/index'));

const AdminGalleryLazy = lazyRetry(() => import('../../pages/AdminGallery/index'));
const AdminContestsLazy = lazyRetry(() => import('../../pages/AdminContests/index'));
const AdminFeatsLazy = lazyRetry(() => import('../../pages/AdminFeats/index'));

const router = createBrowserRouter([
    {
        path: APP__ROUTS.main,
        element: <MainLayout />,
        children: [
            {
                path: APP__ROUTS.notExistRouteRedirect,
                element: <Navigate to={APP__ROUTS.main} replace />,
            },
            {
                index: true,
                path: APP__ROUTS.main,
                element: <Suspense fallback={<PagePreloader />}>{<MainPageLazy />}</Suspense>,
            },

            {
                path: APP__ROUTS.gallery,
                element: (
                    <Suspense fallback={<PagePreloader />}>
                        <GalleryPageLazy />
                    </Suspense>
                ),
                children: [
                    {
                        path: APP__ROUTS.galleryMediaItemId,
                        element: (
                            <Suspense>
                                <GalleryPopUpLazy />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: APP__ROUTS.oldGallery,
                element: (
                    <Suspense fallback={<PagePreloader />}>
                        <GalleryPageLazy />
                    </Suspense>
                ),
                children: [
                    {
                        path: APP__ROUTS.galleryMediaItemId,
                        loader: loadGalleryMediaItemById,
                        element: (
                            <Suspense>
                                <GalleryPopUpLazy />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: APP__ROUTS.feats,
                element: (
                    <Suspense fallback={<PagePreloader />}>
                        <FeatsPageLazy />
                    </Suspense>
                ),
                children: [
                    {
                        path: APP__ROUTS.featsId,
                        loader: loadFeatById,
                        element: (
                            <Suspense>
                                <FeatPopUpLazy />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: APP__ROUTS.contests,
                element: (
                    <Suspense fallback={<PagePreloader />}>
                        <ContestPageLazy />
                    </Suspense>
                ),
                children: [
                    {
                        path: APP__ROUTS.registration,
                        element: <></>,
                    },
                ],
            },
            // {
            //     path: APP__ROUTS.puzzle,
            //     element: (
            //         <Suspense fallback={<PagePreloader />}>
            //             <PuzzlePageLazy />
            //         </Suspense>
            //     ),
            //     children: [
            //         {
            //             index: true,
            //             element: (
            //                 <Suspense>
            //                     <PuzzleStartPageLazy />
            //                 </Suspense>
            //             ),
            //         },
            //         {
            //             path: APP__ROUTS.puzzleGame,
            //             element: (
            //                 <Suspense>
            //                     <PuzzleGamePageLazy />
            //                 </Suspense>
            //             ),
            //         },
            //         {
            //             path: APP__ROUTS.puzzleTest,
            //             element: (
            //                 <Suspense>
            //                     <PuzzleTestPageLazy />
            //                 </Suspense>
            //             ),
            //         },
            //         {
            //             path: APP__ROUTS.puzzleFinal,
            //             element: (
            //                 <Suspense>
            //                     <PuzzleFinalLvl />
            //                 </Suspense>
            //             ),
            //         },
            //         {
            //             path: APP__ROUTS.puzzleRefQuestion,
            //             element: (
            //                 <Suspense>
            //                     <PuzzleRefQuestion />
            //                 </Suspense>
            //             ),
            //         },
            //     ],
            // },
        ],
    },
    {
        path: APP__ROUTS.profileLayout,
        element: <ProfileLayout />,
        children: [
            {
                path: APP__ROUTS.userLayout,
                element: (
                    <Suspense>
                        <UserPageLazy />
                    </Suspense>
                ),
            },
            {
                path: APP__ROUTS.adminLayout,
                element: <AdminLayout />,
                children: [
                    {
                        index: true,
                        path: APP__ROUTS.adminGallery,
                        element: (
                            <Suspense fallback={<PagePreloader variant="admin" />}>
                                <AdminGalleryLazy />
                            </Suspense>
                        ),
                    },
                    {
                        path: APP__ROUTS.adminContests,
                        element: (
                            <Suspense fallback={<PagePreloader variant="admin" />}>
                                <AdminContestsLazy />
                            </Suspense>
                        ),
                    },
                    {
                        path: APP__ROUTS.adminFeats,
                        element: (
                            <Suspense fallback={<PagePreloader variant="admin" />}>
                                <AdminFeatsLazy />
                            </Suspense>
                        ),
                    },
                ],
            },
        ],
    },
]);
export default router;
